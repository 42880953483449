import { useEffect } from 'react';
import styled from '@emotion/styled';

import { Cinema, Date } from '@app/screening-times/src/api';

import useScreeningsForCinemaOnDate from '@app/screening-times/src/hooks/useScreeningsForCinemaOnDate';

import { BackArrow } from '@app/screening-times/src/components/Icons';
import CinemaAndScreeningsItem from '@app/screening-times/src/components/results-sections/CinemaAndScreeningsItem';
import {
  CinemaResultsContainer,
  CinemasColumn,
} from '@app/screening-times/src/components/results-sections/StyledResultsComponents';
import LoadingState from '@app/screening-times/src/components/utils/LoadingState';
import NoResultsCopy from '@app/screening-times/src/components/utils/NoResultsCopy';

type OneCinemaOnDateProps = {
  filmId: number;
  cinema: Cinema;
  dateString: string;
  setSelectedCinema: (cinema: Cinema) => void;
  setShowDates: (showDates: Date[]) => void;
  countryCode: string;
};

const OneCinemaOnDate = ({
  filmId,
  cinema,
  dateString,
  setSelectedCinema,
  setShowDates,
  countryCode,
}: OneCinemaOnDateProps) => {
  const [screenings, showDates, isLoading] = useScreeningsForCinemaOnDate(
    filmId,
    cinema.cinema_id,
    dateString,
    countryCode,
    setSelectedCinema,
  );

  useEffect(() => {
    setShowDates(showDates);
  }, [JSON.stringify(showDates)]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (Array.isArray(screenings) && screenings.length === 0) {
    return (
      <NoResultsCopy>
        There are currently no screenings available in this cinema on this date
      </NoResultsCopy>
    );
  }

  return (
    <>
      <CinemaResultsContainer>
        <CinemasColumn>
          <CinemaAndScreeningsItem
            cinema={cinema}
            filmId={filmId}
            showDividerLine={false}
            countryCode={countryCode}
          />
        </CinemasColumn>
      </CinemaResultsContainer>
      <BackButton onClick={() => setSelectedCinema(null)}>
        <BackArrow width="8px" />
        <Gap width="8px" /> Back to all cinemas
      </BackButton>
    </>
  );
};

export default OneCinemaOnDate;

const BackButton = styled.div`
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  color: ${props => props.theme.color.midGray};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-top: 32px;
`;

const Gap = styled.div<{
  width: string;
}>`
  width: ${props => props.width};
`;
