import { differenceInCalendarDays, format } from 'date-fns';

import { fetchPurchaseConfirmation } from '@app/screening-times/src/api';
import { fetchPurchaseConfirmationCA } from '@app/screening-times/src/apiCA';
import { fetchPurchaseConfirmationDE } from '@app/screening-times/src/apiDE';
import { fetchPurchaseConfirmationIE } from '@app/screening-times/src/apiIE';
import { fetchPurchaseConfirmationUS } from '@app/screening-times/src/apiUS';

const fetchPurchaseConfirmationForCountry = {
  IE: fetchPurchaseConfirmationIE,
  US: fetchPurchaseConfirmationUS,
  GB: fetchPurchaseConfirmation,
  DE: fetchPurchaseConfirmationDE,
  CA: fetchPurchaseConfirmationCA,
};

export const fetchAndGoToScreeningLink = async (
  filmId,
  cinemaId,
  dateString,
  timeString,
  countryCode,
) => {
  let hasOpenedInNewTab = false;
  try {
    const { url } = await fetchPurchaseConfirmationForCountry[countryCode](
      filmId,
      cinemaId,
      dateString,
      timeString,
    );

    const popUp = window.open(url);
    try {
      popUp.focus();
      hasOpenedInNewTab = true;
    } catch (e) {
      // Pop-up Blocker stopped url from opening in new tab
      window.location.href = url;
    }
  } catch {
    // do nothing
  }
  return hasOpenedInNewTab;
};

export const round = x => parseFloat(x.toPrecision(6));

export const getClosestDateText = cinemaDate => {
  if (!cinemaDate) {
    return '';
  }
  const [year, month, day] = cinemaDate.split('-');
  const monthIndex = parseInt(month, 10) - 1;
  const closestDate = new Date(year, monthIndex, day);
  const distanceInDays = differenceInCalendarDays(closestDate, new Date());

  let dateText = 'Showing';
  if (distanceInDays === 1) {
    dateText = 'Starts Tomorrow';
  }
  if (distanceInDays >= 2) {
    dateText = format(closestDate, "'Starts' MMMM d");
  }

  return dateText;
};
