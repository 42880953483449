import React from 'react';

import { color as themeColors } from '@app/themes/mubi-theme';

type IconProps = {
  color?: string;
  width?: string;
};

export const PickLocation = ({
  color = themeColors.black,
  width = '20px',
}: IconProps) => (
  <svg viewBox="3 3 18 18" width={width}>
    <path
      fill={color}
      d="M12.5 3.5v1.52a7 7 0 016.48 6.48h1.52v1h-1.52a7 7 0 01-6.48 6.48v1.52h-1v-1.52a7 7 0 01-6.48-6.48H3.5v-1h1.52a7 7 0 016.48-6.48V3.5h1zM12 6a6 6 0 100 12 6 6 0 000-12zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 010-7z"
    />
  </svg>
);

const mubiDotsCircles = (xOffset = 0, yOffset = 0, color) => (
  <g fill={color}>
    {/* order is important, as affects loading animation */}
    <circle cx={xOffset + 206.5} cy={yOffset + 118.11} r="33.13" />
    <circle cx={xOffset + 35.89} cy={yOffset + 118.11} r="33.13" />
    <circle cx={xOffset + 121.2} cy={yOffset + 118.11} r="33.13" />
    <circle cx={xOffset + 35.89} cy={yOffset + 33.13} r="33.13" />
    <circle cx={xOffset + 121.2} cy={yOffset + 33.13} r="33.13" />
    <circle cx={xOffset + 35.89} cy={yOffset + 203.1} r="33.13" />
    <circle cx={xOffset + 121.2} cy={yOffset + 203.1} r="33.13" />
  </g>
);

export const LoadingIcon = ({
  color = themeColors.darkText,
  width = null,
}: IconProps) => (
  <svg viewBox="0 0 240 240" fill={color} width={width}>
    <defs>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            @keyframes fade {
              from { opacity: 0.2; }
              to { opacity: 1; }
            }
            .LoadingIconGroup circle { animation: fade 0.4s linear 0s infinite alternate both; }
            .LoadingIconGroup circle:nth-child(2) { animation-delay: 300ms}
            .LoadingIconGroup circle:nth-child(3) { animation-delay: 600ms }
            .LoadingIconGroup circle:nth-child(4) { animation-delay: 800ms }
            .LoadingIconGroup circle:nth-child(5) { animation-delay: 400ms }
            .LoadingIconGroup circle:nth-child(6) { animation-delay: 500ms }
            .LoadingIconGroup circle:nth-child(7) { animation-delay: 200ms }
          `,
        }}
      />
    </defs>
    <g className="LoadingIconGroup">{mubiDotsCircles(0, 0, color)}</g>
  </svg>
);

export const BackArrow = ({
  color = themeColors.midGray,
  width = null,
}: IconProps) => (
  <svg viewBox="0 0 8 20" width={width}>
    <path
      d="M.18 10.41a1.1 1.1 0 010-1.2L6.15.22A.48.48 0 016.84.1l.94.66a.5.5 0 01.14.69l-5.58 8.4 5.57 8.75a.5.5 0 01-.14.69l-.96.64a.48.48 0 01-.68-.15L.18 10.41z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export const CalendarIcon = ({
  color = themeColors.black,
  width = null,
}: IconProps) => (
  <svg viewBox="0 0 16 17" width={width}>
    <path
      d="M11.48 9.17H8.87A.85.85 0 008 10v2.5c0 .46.4.83.87.83h2.6c.49 0 .88-.37.88-.83V10c0-.46-.4-.83-.87-.83zm0-8.34v.84H4.52V.83c0-.45-.39-.83-.87-.83a.85.85 0 00-.87.83v.84h-.87A1.7 1.7 0 00.18 3.33V15c0 .92.77 1.67 1.73 1.67H14.1A1.7 1.7 0 0015.83 15V3.33a1.7 1.7 0 00-1.74-1.66h-.87V.83c0-.45-.4-.83-.87-.83a.85.85 0 00-.87.83zM13.22 15H2.78a.85.85 0 01-.87-.83V5.83H14.1v8.34c0 .46-.4.83-.87.83z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export const ChevronLeft = ({
  color = themeColors.black,
  width = '27px',
}: IconProps) => (
  <svg width={width} height="33px" viewBox="10 5 10 18">
    <polygon
      fill={color}
      points="18.4197656 8.84935252 16.734375 7.16546763 9.5625 14.3309353 16.734375 21.4964029 18.4197656 19.812518 12.9452344 14.3309353"
    />
  </svg>
);

export const ChevronRight = ({
  color = themeColors.black,
  width = '27px',
}: IconProps) => (
  <svg
    width={width}
    height="33px"
    viewBox="10 5 10 18"
    style={{ transform: 'scale(-1, 1)' }}
  >
    <polygon
      fill={color}
      points="18.4197656 8.84935252 16.734375 7.16546763 9.5625 14.3309353 16.734375 21.4964029 18.4197656 19.812518 12.9452344 14.3309353"
    />
  </svg>
);
