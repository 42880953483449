import { Suggestion } from 'use-places-autocomplete';
import styled from '@emotion/styled';

type LocationSuggestionsProps = {
  suggestions?: Suggestion[];
  onSuggestionClick: (suggestion: string) => void;
  highlightedIndex?: number;
};
const LocationSuggestions = ({
  suggestions = [],
  onSuggestionClick,
  highlightedIndex = null,
}: LocationSuggestionsProps) => (
  <SuggestionsContainer>
    {suggestions.map((suggestion, i) => (
      <Suggestion
        key={suggestion.place_id}
        onClick={() => onSuggestionClick(suggestion.description)}
        highlighted={highlightedIndex === i}
      >
        {suggestion.description}
      </Suggestion>
    ))}
  </SuggestionsContainer>
);

export default LocationSuggestions;

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.1);
`;

const Suggestion = styled.div<{
  highlighted: boolean;
}>`
  cursor: pointer;
  font-weight: normal;
  background-color: ${props => (props.highlighted ? 'lightgrey' : 'white')};
  :hover {
    background-color: lightgrey;
  }
  padding: 8px 16px;
`;
