const BASE_URL = 'https://api-gate2.movieglu.com';
const REQUIRED_HEADERS_DE = {
  client: 'SPJP_DE',
  'x-api-key': 'EkBfOyIPVb7WDFKeF21Um2RTnet4Fsrt4grvQuWx',
  authorization: 'Basic U1BKUF9ERTptbk5JdVNvR0FzVWE=',
  territory: 'DE',
  'api-version': 'v200',
  'device-datetime': new Date().toISOString(),
};

export const fetchPurchaseConfirmationDE = (
  filmId: number,
  cinemaId: number,
  dateString: string,
  timeString: string,
) =>
  fetch(
    `${BASE_URL}/purchaseConfirmation/?film_id=${filmId}&cinema_id=${cinemaId}&date=${dateString}&time=${timeString}`,
    { headers: REQUIRED_HEADERS_DE },
  ).then(res => res.json());

// bespoke apis

export const fetchMubiClosestShowingDE = (
  filmId: number,
  latLongString: string,
) =>
  fetch(`${BASE_URL}/mubiClosestShowing/?film_id=${filmId}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_DE },
  }).then(res => res.json());

export const fetchMubiScreeningsForLocationAndDateDE = (
  filmId: number,
  latLongString: string,
  dateString: string,
) =>
  fetch(`${BASE_URL}/mubiShowTimesFilm/?film_id=${filmId}&date=${dateString}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_DE },
  }).then(res => res.json());

export const fetchMubiScreeningsForCinemaIdAndDateDE = (
  filmId: number,
  cinemaId: number,
  dateString: string,
) =>
  fetch(
    `${BASE_URL}/mubiShowTimesCinema/?cinema_id=${cinemaId}&date=${dateString}&film_id=${filmId}`,
    { headers: REQUIRED_HEADERS_DE },
  ).then(res => res.json());
