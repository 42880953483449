import { ReactNode, useEffect, useRef, useState } from 'react';

type ClickOutsideProps = {
  children: ReactNode;
  onClickOutside: (ev: MouseEvent | TouchEvent) => void;
  active?: boolean;
};

const ClickOutside = ({
  children,
  onClickOutside,
  active = true,
}: ClickOutsideProps) => {
  const container = useRef(null);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    if (active) {
      startListening();
    }

    if (!active) {
      stopListening();
    }

    return () => stopListening();
  }, [active]);

  const startListening = () => {
    document.addEventListener('touchend', handleClick, true);
    document.addEventListener('click', handleClick, true);
  };

  const stopListening = () => {
    document.removeEventListener('touchend', handleClick, true);
    document.removeEventListener('click', handleClick, true);
  };

  const handleClick = (ev: MouseEvent | TouchEvent) => {
    if (ev.type === 'touchend') {
      setIsTouch(true);
    }
    if (ev.type === 'click' && isTouch) {
      return;
    }
    const el = container.current;
    if (el && !el.contains(ev.target)) {
      onClickOutside(ev);
    }
  };

  return <span ref={container}>{children}</span>;
};

export default ClickOutside;
