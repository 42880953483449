import { Cinema, Date } from '@app/screening-times/src/api';

import ClosestCinemas from '@app/screening-times/src/components/results-sections/ClosestCinemas';
import ClosestCinemasOnDate from '@app/screening-times/src/components/results-sections/ClosestCinemasOnDate';
import OneCinemaOnDate from '@app/screening-times/src/components/results-sections/OneCinemaOnDate';
import LoadingState from '@app/screening-times/src/components/utils/LoadingState';

type CinemasResultsSectionProps = {
  filmId: number;
  latLongString: string;
  dateString: string;
  selectedCinema: Cinema;
  setSelectedCinema: (cinema: Cinema) => void;
  setShowDatesForFilmAndLocation: (dates: Date[]) => void;
  setShowDatesForCinema: (dates: Date[]) => void;
  countryCode: string;
};

const CinemasResultsSection = ({
  filmId,
  latLongString,
  dateString,
  selectedCinema,
  setSelectedCinema,
  setShowDatesForFilmAndLocation,
  setShowDatesForCinema,
  countryCode,
}: CinemasResultsSectionProps) => {
  if (!latLongString) {
    return <LoadingState />;
  }

  if (selectedCinema) {
    if (!dateString) {
      return <LoadingState />;
    }
    return (
      <OneCinemaOnDate
        filmId={filmId}
        cinema={selectedCinema}
        dateString={dateString}
        setSelectedCinema={setSelectedCinema}
        setShowDates={setShowDatesForCinema}
        countryCode={countryCode}
      />
    );
  }

  if (dateString) {
    return (
      <ClosestCinemasOnDate
        filmId={filmId}
        latLongString={latLongString}
        dateString={dateString}
        setSelectedCinema={setSelectedCinema}
        countryCode={countryCode}
      />
    );
  }

  return (
    <ClosestCinemas
      filmId={filmId}
      latLongString={latLongString}
      setSelectedCinema={setSelectedCinema}
      setShowDates={setShowDatesForFilmAndLocation}
      countryCode={countryCode}
    />
  );
};

export default CinemasResultsSection;
