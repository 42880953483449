import { toIsoString } from '@app/screening-times/src/date-utils';

const BASE_URL = 'https://api-gate2.movieglu.com';

const REQUIRED_HEADERS_CA = {
  client: 'SPJP_CA',
  'x-api-key': '9D1auQqXeyaUMYXzLrFKV7UCNmmk9HQ72qR8BAji',
  authorization: 'Basic U1BKUF9DQToyNUVOQ1VTakgxVWY=',
  territory: 'CA',
  'api-version': 'v200',
  'device-datetime': toIsoString(new Date()),
};

export const fetchPurchaseConfirmationCA = (
  filmId,
  cinemaId,
  dateString,
  timeString,
) =>
  fetch(
    `${BASE_URL}/purchaseConfirmation/?film_id=${filmId}&cinema_id=${cinemaId}&date=${dateString}&time=${timeString}`,
    { headers: REQUIRED_HEADERS_CA },
  ).then(res => res.json());

// bespoke apis

export const fetchMubiClosestShowingCA = (filmId, latLongString) =>
  fetch(`${BASE_URL}/mubiClosestShowing/?film_id=${filmId}&date_sort=1`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_CA },
  }).then(res => res.json());

export const fetchMubiScreeningsForLocationAndDateCA = (
  filmId,
  latLongString,
  dateString,
) =>
  fetch(`${BASE_URL}/mubiShowTimesFilm/?film_id=${filmId}&date=${dateString}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_CA },
  }).then(res => res.json());

export const fetchMubiScreeningsForCinemaIdAndDateCA = (
  filmId,
  cinemaId,
  dateString,
) =>
  fetch(
    `${BASE_URL}/mubiShowTimesCinema/?cinema_id=${cinemaId}&date=${dateString}&film_id=${filmId}`,
    { headers: REQUIRED_HEADERS_CA },
  ).then(res => res.json());
