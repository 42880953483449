import { useEffect, useState } from 'react';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import styled from '@emotion/styled';

import constants from '@app/screening-times/src/constants';

import ImageBase from '@app/components/ImageBase';
import DateInput from '@app/screening-times/src/components/inputs/date/DateInput';
import LocationInput from '@app/screening-times/src/components/inputs/location/LocationInput';
import CinemasResultsSection from '@app/screening-times/src/components/results-sections/CinemasResultsSection';

const isDateInTheFuture = theDate =>
  isAfter(parseISO(`${theDate} 23:59:59`), new Date());

type SectionScreeningTimesProps = {
  filmId: number;
  userCountryCode: string;
  filmStartDate?: string;
  initialLat: number;
  initialLong: number;
};

const SectionScreeningTimes = ({
  filmId,
  userCountryCode,
  filmStartDate = '',
  initialLat,
  initialLong,
}: SectionScreeningTimesProps) => {
  const [latLongString, setLatLongString] = useState(
    `${initialLat};${initialLong}`,
  );
  const [dateString, setDateString] = useState(() => {
    if (filmStartDate?.length === 10 && isDateInTheFuture(filmStartDate)) {
      // The start date is in the future
      return filmStartDate;
    }
    return '';
  });
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [showDatesForFilmAndLocation, setShowDatesForFilmAndLocation] =
    useState([]);
  const [showDatesForCinema, setShowDatesForCinema] = useState([]);

  useEffect(() => {
    if (selectedCinema?.cinema_id) {
      const closestDateTime =
        selectedCinema?.showings?.Standard?.times?.[0]?.date_time;
      setDateString(closestDateTime?.split('T')?.[0]);
    }
  }, [selectedCinema?.cinema_id]);

  useEffect(() => {
    setSelectedCinema(null);
  }, [latLongString]);

  if (!['GB', 'IE', 'US', 'DE', 'CA'].includes(userCountryCode)) {
    return null;
  }

  return (
    <Container>
      <FindFilmTitle>Find a screening near you</FindFilmTitle>
      <InputsContainer>
        <LocationInputFormField>
          <LocationInput
            setLatLongString={setLatLongString}
            latLongString={latLongString}
            initialLat={initialLat}
            initialLong={initialLong}
          />
        </LocationInputFormField>
        <DatePickerFormField>
          <DateInput
            dateString={dateString}
            setDateString={setDateString}
            highlightDates={
              selectedCinema ? showDatesForCinema : showDatesForFilmAndLocation
            }
          />
        </DatePickerFormField>
      </InputsContainer>

      <CinemasResultsSection
        filmId={filmId}
        latLongString={latLongString}
        dateString={dateString}
        selectedCinema={selectedCinema}
        setSelectedCinema={setSelectedCinema}
        setShowDatesForFilmAndLocation={setShowDatesForFilmAndLocation}
        setShowDatesForCinema={setShowDatesForCinema}
        countryCode={userCountryCode}
      />
      <MovieGluDetailsContainer>
        <MovieGluImage
          alt="Movie Glu logo"
          src={`${constants.assetPrefix}/website/release-screenings/movie-glu-logo.png`}
        />
        <MovieGluText>
          Showtimes data under licence from MovieGlu Ltd.
        </MovieGluText>
      </MovieGluDetailsContainer>
    </Container>
  );
};

export default SectionScreeningTimes;

const Container = styled.div`
  width: 100%;
  min-height: 430px;
  font-family: DMSans, Helvetica, Arial, 'Lucida Grande', sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
`;

const FindFilmTitle = styled.h2`
  margin: 0;
  margin-bottom: 8px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;

  color: ${props => props.theme.color.midGray};
`;

const FormField = styled.div`
  &:nth-of-type(1) {
    margin-bottom: 20px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: inline-flex;
    margin-right: 10px;
    &:nth-of-type(1) {
      margin-bottom: 0;
    }
  }
`;

const LocationInputFormField = styled(FormField)`
  width: 100%;
`;
const DatePickerFormField = styled(FormField)`
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin-right: 0;
  }
`;

const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    flex-direction: row;
    width: calc(50% - 50px);
    margin-bottom: 52px;
  }
`;

const MovieGluDetailsContainer = styled.div`
  display: flex;
  padding: 50px 0 16px 0;
  flex-direction: column;
  align-items: flex-end;
`;
const MovieGluImage = styled(ImageBase)`
  height: 20px;
`;
const MovieGluText = styled.div`
  line-height: 13px;
  font-weight: normal;
  font-size: 10px;
  color: ${props => props.theme.color.midGray};

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    line-height: 13px;
    padding-left: 10px;
  }
`;
