import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { round } from '@app/screening-times/src/services';

import useAutocomplete, {
  getLatLongStringAndCountryFromDescription,
} from '@app/screening-times/src/hooks/useAutocomplete';

import { PickLocation } from '@app/screening-times/src/components/Icons';
import LocationSuggestions from '@app/screening-times/src/components/inputs/location/LocationSuggestions';
import { InputContainer, Label, TextInput } from '@app/screening-times/src/components/inputs/StyledFormComponents';
import ClickOutside from '@app/screening-times/src/components/utils/ClickOutside';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const PickLocationContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

const LocationInputContainer = styled(InputContainer)`
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 100%;
  }
`;

/* 
example locations coming from: https://developer.movieglu.com/sandbox/

in the text field try
Walvis Bay, Namibia
or
Gobabeb, Namibia
*/
const LocationInput = ({
  setLatLongString,
  latLongString,
  initialLat,
  initialLong,
}) => {
  const { value, suggestions, setValue, clearSuggestions } = useAutocomplete(
    initialLat,
    initialLong,
  );
  const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] =
    useState(null);

  const selectSuggestion = async description => {
    setValue(description, false);
    setHighlightedSuggestionIndex(null);
    clearSuggestions();

    const [newLatLongString] = await getLatLongStringAndCountryFromDescription(
      description,
    );
    setLatLongString(newLatLongString);
  };

  const setLocationFromGeolocation = () => {
    const prevLatLngString = latLongString;
    setLatLongString('');
    navigator.geolocation.getCurrentPosition(
      async pos => {
        setValue('Current location', false);
        const { latitude, longitude } = pos.coords;
        setLatLongString(`${round(latitude)};${round(longitude)}`);
      },
      () => {
        // Revert to previous latLngString
        setLatLongString(prevLatLngString);
      },
    );
  };

  const handleKeyDown = e => {
    const keyCode = e.key || e.code;
    switch (keyCode) {
      case 'Escape':
        setHighlightedSuggestionIndex(null);
        clearSuggestions();
        break;
      case 'ArrowDown':
        if (highlightedSuggestionIndex === null) {
          setHighlightedSuggestionIndex(0);
          break;
        }

        if (highlightedSuggestionIndex < suggestions.length - 1) {
          setHighlightedSuggestionIndex(highlightedSuggestionIndex + 1);
        }
        break;
      case 'ArrowUp':
        if (highlightedSuggestionIndex) {
          setHighlightedSuggestionIndex(highlightedSuggestionIndex - 1);
        }
        break;
      case 'Enter':
        selectSuggestion(suggestions[highlightedSuggestionIndex].description);
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Label>Where</Label>
      <LocationInputContainer wide>
        <TextInput
          value={value}
          placeholder="Type a location"
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          data-cy="location-input"
        />
        <PickLocationContainer onClick={setLocationFromGeolocation}>
          <PickLocation />
        </PickLocationContainer>
        <ClickOutside
          active={suggestions.length > 0}
          onClickOutside={() => {
            setHighlightedSuggestionIndex(null);
            clearSuggestions();
          }}
        >
          <LocationSuggestions
            suggestions={suggestions}
            onSuggestionClick={selectSuggestion}
            highlightedIndex={highlightedSuggestionIndex}
          />
        </ClickOutside>
      </LocationInputContainer>
    </Container>
  );
};
LocationInput.propTypes = {
  setLatLongString: PropTypes.func.isRequired,
  latLongString: PropTypes.string.isRequired,
  initialLat: PropTypes.number.isRequired,
  initialLong: PropTypes.number.isRequired,
};

export default LocationInput;
