import { toIsoString } from '@app/screening-times/src/date-utils';

const BASE_URL = 'https://api-gate2.movieglu.com';

const REQUIRED_HEADERS_US = {
  client: 'SPJP_US',
  'x-api-key': 'VkBLl64qlM5Fhb0Qswr6s58doLItIEJL5UYi4bC7',
  authorization: 'Basic U1BKUF9VUzp2bUVnNFM4MU4wTjg=',
  territory: 'US',
  'api-version': 'v200',
  'device-datetime': toIsoString(new Date()),
};

export const fetchPurchaseConfirmationUS = (
  filmId,
  cinemaId,
  dateString,
  timeString,
) =>
  fetch(
    `${BASE_URL}/purchaseConfirmation/?film_id=${filmId}&cinema_id=${cinemaId}&date=${dateString}&time=${timeString}`,
    { headers: REQUIRED_HEADERS_US },
  ).then(res => res.json());

// bespoke apis

export const fetchMubiClosestShowingUS = (filmId, latLongString) =>
  fetch(
    `${BASE_URL}/mubiClosestShowing/?film_id=${filmId}&radius=50&date_sort=1`,
    {
      headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_US },
    },
  ).then(res => res.json());

export const fetchMubiScreeningsForLocationAndDateUS = (
  filmId,
  latLongString,
  dateString,
) =>
  fetch(
    `${BASE_URL}/mubiShowTimesFilm/?film_id=${filmId}&date=${dateString}&radius=50`,
    {
      headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_US },
    },
  ).then(res => res.json());

export const fetchMubiScreeningsForCinemaIdAndDateUS = (
  filmId,
  cinemaId,
  dateString,
) =>
  fetch(
    `${BASE_URL}/mubiShowTimesCinema/?cinema_id=${cinemaId}&date=${dateString}&film_id=${filmId}`,
    { headers: REQUIRED_HEADERS_US },
  ).then(res => res.json());
