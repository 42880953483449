import styled from '@emotion/styled';

const NoResultsCopy = styled.div`
  font-weight: bold;
  margin: 40px 0;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin: 52px 0;
  }
`;
export default NoResultsCopy;
