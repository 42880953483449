import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Cinema } from '@app/screening-times/src/api';

import { getClosestDateText } from '@app/screening-times/src/services';

import ScreeningTime from '@app/screening-times/src/components/results-sections/ScreeningTime';

const getLocationString = cinema => {
  let location = '';
  if (cinema?.city) {
    location = `${cinema.city}, `;
  }
  if (cinema?.state) {
    location = `${location}${cinema.state}, `;
  }

  location = `${location}${Math.round(cinema.distance)}mi.`;

  return location;
};

type CinemaAndScreeningsItemProps = {
  cinema: Cinema;
  setSelectedCinema?: (cinema: Cinema) => void;
  filmId: number;
  showClosestDate?: boolean;
  showDividerLine?: boolean;
  countryCode: string;
};

const CinemaAndScreeningsItem = ({
  cinema,
  setSelectedCinema = null,
  filmId,
  showClosestDate = false,
  showDividerLine = true,
  countryCode,
}: CinemaAndScreeningsItemProps) => {
  const { cinema_id, cinema_name, showings } = cinema;
  const closestDateTime = showings?.Standard?.times?.[0]?.date_time;
  const closestScreeningDate = getClosestDateText(
    closestDateTime?.split('T')[0],
  );

  const doOnCinemaClick = () => {
    if (setSelectedCinema) {
      setSelectedCinema(cinema);
    }
  };

  const screeningTimes = cinema?.showings?.Standard?.times || [];
  const numScreeningTimes = screeningTimes.length;
  const numScreeningTimesRows = Math.min(numScreeningTimes, 3);

  return (
    <CinemaAndShowings
      key={cinema_id}
      showClosestDate={showClosestDate}
      showDividerLine={showDividerLine}
    >
      <CinemaNameContainer>
        <NameAndDistanceContainer>
          <CinemaName
            cursorPointer={!!setSelectedCinema}
            onClick={doOnCinemaClick}
          >
            {cinema_name}
          </CinemaName>
          &nbsp;&nbsp;
          <Location>{getLocationString(cinema)}</Location>
        </NameAndDistanceContainer>
        {showClosestDate && <ClosestDate>{closestScreeningDate}</ClosestDate>}
      </CinemaNameContainer>
      <ScreeningsContainer
        showClosestDate={showClosestDate}
        numScreeningTimesRows={numScreeningTimesRows}
      >
        {screeningTimes?.map(screening => (
          <ScreeningTimeContainer
            key={`${cinema.cinema_id}-${screening.date_time}`}
          >
            <ScreeningTime
              screeningTime={screening}
              cinema={cinema}
              filmId={filmId}
              countryCode={countryCode}
            />
          </ScreeningTimeContainer>
        ))}
      </ScreeningsContainer>
    </CinemaAndShowings>
  );
};

export default CinemaAndScreeningsItem;

const hoverStyles = css`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CinemaNameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NameAndDistanceContainer = styled.div`
  padding-right: 20px;
`;

const CinemaName = styled.span<{
  cursorPointer: boolean;
}>`
  font-weight: bold;
  font-size: 16px;

  ${props => props.cursorPointer && hoverStyles}
`;

const ClosestDate = styled.div`
  color: ${props => props.theme.color.midGray};
  text-transform: uppercase;
  font-size: 12px;
  padding-top: 2px;
  height: 23px;
  white-space: nowrap;
`;

const Location = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.midGray};
`;

const CinemaAndShowings = styled.div<{
  showClosestDate: boolean;
  showDividerLine: boolean;
}>`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: normal;

  ${props =>
    props.showDividerLine &&
    `border-bottom: 1px solid ${props.theme.color.midBackground}; margin-bottom: 16px;`}
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    align-items: flex-end;
    ${props =>
      !props.showClosestDate &&
      'flex-direction:  row; align-items: flex-start;'};
  }
`;

const ScreeningsContainer = styled.div<{
  showClosestDate: boolean;
  numScreeningTimesRows: number;
}>`
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    ${props => !props.showClosestDate && 'padding-top: 0;'};
    justify-content: flex-end;
    min-width: ${props => `calc(${props.numScreeningTimesRows} * 92px)`};
    max-width: ${props => `calc(${props.numScreeningTimesRows} * 92px)`};
  }
`;

const ScreeningTimeContainer = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin-right: 0;
    margin-left: 4px;
  }
`;
