import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const inputStyles = props => css`
  box-sizing: content-box;
  font-family: inherit;
  width: 100%;
  height: 18px;
  background-color: ${props.theme.color.lightBackground};
  font-size: 14px;
  line-height: 18px;
  color: ${props.theme.color.black};
  text-align: left;
  border: none;
  outline: none;
  padding: 7px 34px 7px 8px;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.color.black};
  text-align: left;
  width: 85px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 64px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: auto;
    margin-right: 10px;
  }
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    flex-grow: 0;
  }

  width: calc(100% - 85px);
  background-color: ${props => props.theme.color.lightBackground};
  align-items: center;
  display: inline-flex;
  vertical-align: top;
  position: relative;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 220px;
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    width: ${props => (props.wide ? '310px' : '220px')};
  }
`;

export const TextInput = styled.input`
  ${inputStyles}

  // firexfox 82 fix
  overflow: hidden;
`;
