import styled from '@emotion/styled';

export const CinemaResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    flex-direction: row;
  }
`;

export const CinemasColumn = styled.div`
  flex-basis: calc(50% - 50px);
`;
