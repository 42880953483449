const BASE_URL = 'https://api-gate2.movieglu.com';
// const BASE_URL = 'https://api-v2.movieglu.com/io';
const REQUIRED_HEADERS_IE = {
  client: 'SPJP_IE',
  'x-api-key': 'C4yLJhLiwy5Rw62mLdg6b7PqaRQ3vH6v4k2X8U4H',
  authorization: 'Basic U1BKUF9JRTpKNVRXczdRYzVoWU8=',
  territory: 'IE',
  'api-version': 'v200',
  'device-datetime': new Date().toISOString(),
};

export const fetchPurchaseConfirmationIE = (
  filmId,
  cinemaId,
  dateString,
  timeString,
) =>
  fetch(
    `${BASE_URL}/purchaseConfirmation/?film_id=${filmId}&cinema_id=${cinemaId}&date=${dateString}&time=${timeString}`,
    { headers: REQUIRED_HEADERS_IE },
  ).then(res => res.json());

// bespoke apis

export const fetchMubiClosestShowingIE = (filmId, latLongString) =>
  fetch(`${BASE_URL}/mubiClosestShowing/?film_id=${filmId}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_IE },
  }).then(res => res.json());

export const fetchMubiScreeningsForLocationAndDateIE = (
  filmId,
  latLongString,
  dateString,
) =>
  fetch(`${BASE_URL}/mubiShowTimesFilm/?film_id=${filmId}&date=${dateString}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS_IE },
  }).then(res => res.json());

export const fetchMubiScreeningsForCinemaIdAndDateIE = (
  filmId,
  cinemaId,
  dateString,
) =>
  fetch(
    `${BASE_URL}/mubiShowTimesCinema/?cinema_id=${cinemaId}&date=${dateString}&film_id=${filmId}`,
    { headers: REQUIRED_HEADERS_IE },
  ).then(res => res.json());
