import React, { useEffect, useState } from 'react';
import { format as dateFormat, parseISO } from 'date-fns';
import styled from '@emotion/styled';

import { CinemaOnDate, ScreeningTime } from '@app/screening-times/src/api';

import { fetchAndGoToScreeningLink } from '@app/screening-times/src/services';

import { color as themeColors } from '@app/themes/mubi-theme';

import { LoadingIcon } from '@app/screening-times/src/components/Icons';

const Screening = styled.div<{ isLoading: boolean }>`
  cursor: pointer;
  width: 80px;
  height: 26px;
  line-height: 26px;
  border: 1px solid ${props => props.theme.color.lightGray};
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.isLoading ? props.theme.color.lightGray : props.theme.color.white};

  :hover {
    background-color: ${props => props.theme.color.lightGray};
    transition: background-color 0.2s;
  }

  svg {
    display: block;
  }
`;

type ScreeningTimeProps = {
  screeningTime: ScreeningTime;
  cinema: CinemaOnDate;
  filmId: number;
  countryCode: string;
};

const ScreeningTime = ({
  screeningTime,
  cinema,
  filmId,
  countryCode,
}: ScreeningTimeProps) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('pagehide', () => setIsLoading(false));
    window.addEventListener('pageshow', () => setIsLoading(false));
  }, []);

  const onScreeningClick = async screening => {
    setIsLoading(true);
    const [date, time] = screening.date_time.split('T');
    const hasOpenedInNewTab = await fetchAndGoToScreeningLink(
      filmId,
      cinema.cinema_id,
      date,
      time,
      countryCode,
    );
    if (hasOpenedInNewTab) {
      setIsLoading(false);
    }
  };

  return (
    <Screening
      onClick={() => onScreeningClick(screeningTime)}
      isLoading={isLoading}
    >
      {isLoading ? (
        <LoadingIcon color={themeColors.black} width="11px" />
      ) : (
        dateFormat(parseISO(screeningTime.date_time), 'h:mmaaa')
      )}
    </Screening>
  );
};

export default ScreeningTime;
