import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { add, format as dateFormat, parseISO } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import DefaultDatePickerStyles from '@app/components/inputs/date-picker/DefaultDatePickerStyles';
import {
  CalendarIcon,
  ChevronLeft,
  ChevronRight,
} from '@app/screening-times/src/components/Icons';
import {
  InputContainer,
  inputStyles,
  Label,
} from '@app/screening-times/src/components/inputs/StyledFormComponents';

type DateInputProps = {
  dateString: string;
  setDateString: (date: string) => void;
  highlightDates?: {
    date: string;
  }[];
};

const DateInput = ({
  dateString,
  setDateString,
  highlightDates = [],
}: DateInputProps) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (isCalendarOpen) {
      if (window.innerWidth < 1150) {
        disableBodyScroll(document.querySelector('.react-datepicker-popper'));
      }
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isCalendarOpen]);

  useEffect(() => {
    if (!dateString) {
      setSelectedDate(null);
      return;
    }
    setSelectedDate(new Date(`${dateString}T01:00:00`));
  }, [dateString]);

  const formattedHighLightDates =
    highlightDates?.map(highlightDate => parseISO(highlightDate.date)) || [];

  return (
    <Container>
      <Label>When</Label>
      <InputContainer>
        <DatePickerStyles>
          <DatePicker
            minDate={new Date()}
            maxDate={add(new Date(), { years: 1 })}
            dateFormat="iii LLLL d yyyy"
            renderCustomHeader={renderCustomHeader}
            selected={selectedDate}
            onCalendarOpen={() => setIsCalendarOpen(true)}
            onCalendarClose={() => setIsCalendarOpen(false)}
            highlightDates={formattedHighLightDates}
            onChange={date => {
              setSelectedDate(date);
              setDateString(dateFormat(date, 'yyyy-MM-dd'));
            }}
            dayClassName={date => isFilmShowingOnDate(date) && 'has-screenings'}
            placeholderText="Select a date"
            formatWeekDay={day => day[0]}
            customInput={<DatePickerInput />}
          />
        </DatePickerStyles>
        <CalendarIconContainer>
          <CalendarIcon width="15px" />
        </CalendarIconContainer>
      </InputContainer>
      <BlackBackground isCalendarOpen={isCalendarOpen} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    justify-content: flex-end;
  }
`;

const CalendarIconContainer = styled.div`
  padding-right: 8px;
  z-index: 1;
  position: relative;
  pointer-events: none;
  svg {
    display: block;
  }
`;

const datePickerDayStyles = props => css`
  .react-datepicker__day {
    margin: 2px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    color: ${props.theme.color.lightText};
    outline: none;

    @media (min-width: ${props.theme.mqNew.desktop}) {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  .react-datepicker__day-name {
    user-select: none;
    margin: 0;
    font-size: 17px;
    width: 36px;
    color: ${props.theme.color.lightText};

    @media (min-width: ${props.theme.mqNew.desktop}) {
      margin: 0 2px;
      font-size: 12px;
      width: 24px;
    }
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day--disabled {
    opacity: 0.2;
  }

  .react-datepicker__day.react-datepicker__day--selected {
    color: white;
  }

  .react-datepicker__day--highlighted {
    border-radius: 50%;
    background-color: ${props.theme.color.lightGray};

    &:hover {
      background-color: ${props.theme.color.black};
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day:hover {
    border-radius: 100px;
    background-color: black;
    color: white;
    font-weight: normal;
  }

  .react-datepicker__day--today,
  .react-datepicker__day--today.react-datepicker__day--keyboard-selected {
    color: ${props.theme.color.lightText};
    font-weight: normal;
  }

  .react-datepicker__day--keyboard-selected:hover {
    color: white;
    background-color: black;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 100px;
    background-color: white;
    color: black;
    font-weight: normal;
  }
`;

const DatePickerStyles = styled(DefaultDatePickerStyles)`
  width: 100%;
  font-weight: normal;

  .react-datepicker-popper {
    position: fixed !important;
    left: 50% !important;
    top: 200px !important;
    transform: translate(-50%, 0) !important;

    @media (min-width: ${props => props.theme.mqNew.desktop}) {
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      transform: translate(0, 25px) !important;
    }
  }

  input[type='text'] {
    ${inputStyles}
    padding-right: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    border: none;
    border-radius: 0;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__header {
    padding: 0;
    background-color: white;
    border: none;
  }

  .react-datepicker__month {
    font-size: 17px;
    margin: 0;
    padding: 0 20px 20px 20px;

    @media (min-width: ${props => props.theme.mqNew.desktop}) {
      font-size: 12px;
      padding: 0 11px 11px 11px;
    }
  }

  .has-screenings {
    border-radius: 100px;
    border: 1px solid grey;
  }

  ${datePickerDayStyles}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  height: 28px;
  padding: 22px 28px 17px 28px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding: 16px 20px 12px 20px;
  }
`;

const CalendarSelect = styled.select`
  cursor: pointer;
  background-color: ${props => props.theme.color.white};
  border: none;
  color: ${props => props.theme.color.lightText};
  font-size: 14px;
  appearance: none;
  padding-right: 20px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 12px;
  }
`;
const BlackArrow = styled.span`
  cursor: pointer;
  position: relative;
  margin-left: 4px;
  pointer-events: none;
  font-size: 20px;
  left: -20px;
  display: flex;
  align-items: center;
`;

const BlackBackground = styled.div<{ isCalendarOpen: boolean }>`
  display: ${props => (props.isCalendarOpen ? 'block' : 'none')};
  position: fixed;
  background-color: black;
  opacity: 0.2;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;

const LeftChevronContainer = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  user-select: none;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  width: 20px;
  opacity: ${props => (props.disabled ? 0.1 : 1)};
  :hover {
    polygon {
      opacity: 0.5;
    }
  }
  display: flex;
  align-items: center;
`;

const RightChevronContainer = styled(LeftChevronContainer)`
  justify-content: flex-end;
`;

const CalendarSelectContainer = styled.div`
  display: flex;
`;

const monthOptions = [
  { monthName: 'May', monthIndex: 4, year: 2022 },
  { monthName: 'June', monthIndex: 5, year: 2022 },
  { monthName: 'July', monthIndex: 6, year: 2022 },
  { monthName: 'August', monthIndex: 7, year: 2022 },
  { monthName: 'September', monthIndex: 8, year: 2022 },
  { monthName: 'October', monthIndex: 9, year: 2022 },
  { monthName: 'November', monthIndex: 10, year: 2022 },
  { monthName: 'December', monthIndex: 11, year: 2022 },
  { monthName: 'January', monthIndex: 0, year: 2023 },
  { monthName: 'February', monthIndex: 1, year: 2023 },
  { monthName: 'March', monthIndex: 2, year: 2023 },
  { monthName: 'April', monthIndex: 3, year: 2023 },
];

const renderCustomHeader = ({
  date,
  changeMonth,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const onMonthSelect = ({ target: { value: selectedMonthIndex } }) => {
    const { year } = monthOptions.find(
      monthOption =>
        monthOption.monthIndex === parseInt(selectedMonthIndex, 10),
    );
    changeYear(year);
    changeMonth(selectedMonthIndex);
  };

  return (
    <HeaderContainer>
      <CalendarSelectContainer>
        <CalendarSelect value={date.getMonth()} onChange={onMonthSelect}>
          {monthOptions.map(option => (
            <option key={option.monthName} value={option.monthIndex}>
              {option.monthName} {option.year}
            </option>
          ))}
        </CalendarSelect>
        <BlackArrow>▾</BlackArrow>
      </CalendarSelectContainer>

      <LeftChevronContainer
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeft width="10px" />
      </LeftChevronContainer>
      <RightChevronContainer
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRight width="10px" />
      </RightChevronContainer>
    </HeaderContainer>
  );
};

const DateInputField = styled.div`
  ${inputStyles}
  padding: 7px 8px 7px 8px;
`;

const PlaceholderText = styled.span`
  color: ${props => props.theme.color.lightText};
`;

type DatePickerInputProps = {
  value?: string;
  onClick?: () => void;
};

// eslint-disable-next-line react/display-name
const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(
  ({ value, onClick }, ref) => (
    <DateInputField onClick={onClick} ref={ref} data-cy="date-input">
      {value || <PlaceholderText>Select a date</PlaceholderText>}
    </DateInputField>
  ),
);

const isFilmShowingOnDate = (date: Date | number) => {
  const dateString = dateFormat(date, 'yyyy-MM-dd');
  return ['2021-04-21', '2021-04-22', '2021-04-30'].includes(dateString);
};

export default DateInput;
