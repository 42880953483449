import { useEffect } from 'react';

import { Cinema, Date } from '@app/screening-times/src/api';

import useClosestCinemas from '@app/screening-times/src/hooks/useClosestCinemas';

import ListOfCinemas from '@app/screening-times/src/components/results-sections/ListOfCinemas';
import LoadingState from '@app/screening-times/src/components/utils/LoadingState';
import NoResultsCopy from '@app/screening-times/src/components/utils/NoResultsCopy';

type ClosestCinemasProps = {
  filmId: number;
  latLongString: string;
  setSelectedCinema: (cinema: Cinema) => void;
  setShowDates: (dates: Date[]) => void;
  countryCode: string;
};

const ClosestCinemas = ({
  filmId,
  latLongString,
  setSelectedCinema,
  setShowDates,
  countryCode,
}: ClosestCinemasProps) => {
  const [cinemas, showDates, isLoading] = useClosestCinemas(
    filmId,
    latLongString,
    countryCode,
  );

  useEffect(() => {
    setShowDates(showDates);
  }, [JSON.stringify(showDates)]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (!Array.isArray(cinemas) || cinemas.length === 0) {
    return (
      <NoResultsCopy>
        There are currently no screenings available near this location
      </NoResultsCopy>
    );
  }

  return (
    <ListOfCinemas
      filmId={filmId}
      cinemas={cinemas}
      setSelectedCinema={setSelectedCinema}
      showClosestDate
      countryCode={countryCode}
    />
  );
};

export default ClosestCinemas;
