import { useEffect, useState } from 'react';

import {
  Cinema,
  Date,
  fetchMubiClosestShowing,
} from '@app/screening-times/src/api';
import { fetchMubiClosestShowingCA } from '@app/screening-times/src/apiCA';
import { fetchMubiClosestShowingDE } from '@app/screening-times/src/apiDE';
import { fetchMubiClosestShowingIE } from '@app/screening-times/src/apiIE';
import { fetchMubiClosestShowingUS } from '@app/screening-times/src/apiUS';

const fetchMubiClosestShowingForCountry = {
  IE: fetchMubiClosestShowingIE,
  US: fetchMubiClosestShowingUS,
  GB: fetchMubiClosestShowing,
  DE: fetchMubiClosestShowingDE,
  CA: fetchMubiClosestShowingCA,
};

const useClosestCinemas = (
  filmId,
  latLongString,
  countryCode,
): [Cinema[], Date[], boolean] => {
  const [isLoading, setIsLoading] = useState(true);
  const [cinemas, setCinemas] = useState([]);
  const [showDates, setShowDates] = useState([]);

  const doFetchClosestCinemas = async () => {
    try {
      setIsLoading(true);
      const { cinemas: apiCinemas, show_dates } =
        await fetchMubiClosestShowingForCountry[countryCode](
          filmId,
          latLongString,
        );

      if (apiCinemas) {
        setCinemas(apiCinemas);
      }
      if (show_dates) {
        setShowDates(show_dates);
      }
    } catch {
      setCinemas([]);
      setShowDates([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doFetchClosestCinemas();
  }, [latLongString]);

  return [cinemas, showDates, isLoading];
};

export default useClosestCinemas;
