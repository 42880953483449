import { useEffect, useState } from 'react';

import {
  Cinema,
  fetchMubiScreeningsForLocationAndDate,
} from '@app/screening-times/src/api';
import { fetchMubiScreeningsForLocationAndDateCA } from '@app/screening-times/src/apiCA';
import { fetchMubiScreeningsForLocationAndDateDE } from '@app/screening-times/src/apiDE';
import { fetchMubiScreeningsForLocationAndDateIE } from '@app/screening-times/src/apiIE';
import { fetchMubiScreeningsForLocationAndDateUS } from '@app/screening-times/src/apiUS';

const fetchMubiScreeningsForLocationAndDateForCountry = {
  IE: fetchMubiScreeningsForLocationAndDateIE,
  US: fetchMubiScreeningsForLocationAndDateUS,
  GB: fetchMubiScreeningsForLocationAndDate,
  DE: fetchMubiScreeningsForLocationAndDateDE,
  CA: fetchMubiScreeningsForLocationAndDateCA,
};

/* 
cinemas = [
  {
    cinema_id: 10636,
    cinema_name: "Cinema 6",
    distance: 151.587970423,
    logo_url: "https://assets.movieglu.com/chain_logos/xx/UK-0-sq.jpg",
    showings: {
      Standard: {
        times: [
          {date_time: "2021-05-04T19:00"},
          {date_time: "2021-05-04T20:00"},
        ]
      }
    }
  },
]
*/
const useClosestCinemasOnDate = (
  filmId,
  latLongString,
  dateString,
  countryCode,
): [Cinema[], boolean] => {
  const [isLoading, setIsLoading] = useState(true);
  const [cinemas, setCinemas] = useState([]);

  const doFetchScreenings = async () => {
    setIsLoading(true);
    try {
      const cinemasResponse =
        await fetchMubiScreeningsForLocationAndDateForCountry[countryCode](
          filmId,
          latLongString,
          dateString,
        );
      setCinemas(cinemasResponse.cinemas);
    } catch {
      setCinemas([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doFetchScreenings();
  }, [latLongString, dateString]);

  return [cinemas, isLoading];
};

export default useClosestCinemasOnDate;
