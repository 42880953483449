const BASE_URL = 'https://api-gate2.movieglu.com';
// const BASE_URL = 'https://api-v2.movieglu.com/io';
const REQUIRED_HEADERS = {
  client: 'SPJP_UK',
  'x-api-key': '534eBPWBj46ZjIaGl644K23ZR5K6FLHl9zlgHlfT',
  authorization: 'Basic U1BKUF9VSzprcXVBMTYwMnR6ekM=',
  territory: 'UK',
  'api-version': 'v200',
  'device-datetime': new Date().toISOString(),
};

export type CinemaOnDate = {
  cinema_id?: number;
  cinema_name?: string;
  distance?: number;
  showings?: {
    Standard: {
      times: ScreeningTime[];
    };
  };
  date?: string;
};

export type Cinema = {
  cinema_id?: number;
  cinema_name?: string;
  lat?: number;
  lng?: number;
  address?: string;
  address2?: string;
  city?: string;
  county?: string;
  postcode?: string;
  distance?: number;
  state?: string;
  url?: string;
  showings?: {
    Standard: {
      times: ScreeningTime[];
    };
  };
  date?: string;
};

export type Date = {
  date: string;
};

export type ScreeningTime = {
  date_time: string;
};

export const fetchPurchaseConfirmation = (
  filmId,
  cinemaId,
  dateString,
  timeString,
) =>
  fetch(
    `${BASE_URL}/purchaseConfirmation/?film_id=${filmId}&cinema_id=${cinemaId}&date=${dateString}&time=${timeString}`,
    { headers: REQUIRED_HEADERS },
  ).then(res => res.json());

// bespoke apis

export const fetchMubiClosestShowing = (filmId, latLongString) =>
  fetch(`${BASE_URL}/mubiClosestShowing/?film_id=${filmId}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS },
  }).then(res => res.json());

export const fetchMubiScreeningsForLocationAndDate = (
  filmId,
  latLongString,
  dateString,
) =>
  fetch(`${BASE_URL}/mubiShowTimesFilm/?film_id=${filmId}&date=${dateString}`, {
    headers: { geolocation: `${latLongString}`, ...REQUIRED_HEADERS },
  }).then(res => res.json());

export const fetchMubiScreeningsForCinemaIdAndDate = (
  filmId,
  cinemaId,
  dateString,
) =>
  fetch(
    `${BASE_URL}/mubiShowTimesCinema/?cinema_id=${cinemaId}&date=${dateString}&film_id=${filmId}`,
    { headers: REQUIRED_HEADERS },
  ).then(res => res.json());
