import { useEffect, useState } from 'react';

import {
  Date,
  fetchMubiScreeningsForCinemaIdAndDate,
  ScreeningTime,
} from '@app/screening-times/src/api';
import { fetchMubiScreeningsForCinemaIdAndDateCA } from '@app/screening-times/src/apiCA';
import { fetchMubiScreeningsForCinemaIdAndDateDE } from '@app/screening-times/src/apiDE';
import { fetchMubiScreeningsForCinemaIdAndDateIE } from '@app/screening-times/src/apiIE';
import { fetchMubiScreeningsForCinemaIdAndDateUS } from '@app/screening-times/src/apiUS';

const fetchMubiScreeningsForCinemaIdAndDateForCountry = {
  IE: fetchMubiScreeningsForCinemaIdAndDateIE,
  US: fetchMubiScreeningsForCinemaIdAndDateUS,
  GB: fetchMubiScreeningsForCinemaIdAndDate,
  DE: fetchMubiScreeningsForCinemaIdAndDateDE,
  CA: fetchMubiScreeningsForCinemaIdAndDateCA,
};

const useScreeningsForCinemaOnDate = (
  filmId,
  cinemaId,
  dateString,
  countryCode,
  setSelectedCinema,
): [ScreeningTime[], Date[], boolean] => {
  const [screenings, setScreenings] = useState([]);
  const [showDates, setShowDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const doFetchScreenings = async () => {
    try {
      setIsLoading(true);
      const { films, cinema } =
        await fetchMubiScreeningsForCinemaIdAndDateForCountry[countryCode](
          filmId,
          cinemaId,
          dateString,
        );

      setScreenings(films[0].showings.Standard.times);
      setShowDates(films[0].show_dates);
      setSelectedCinema({
        ...cinema,
        showings: {
          Standard: {
            times: films[0].showings.Standard.times,
          },
        },
      });
    } catch {
      setScreenings([]);
      setShowDates([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doFetchScreenings();
  }, [cinemaId, dateString]);

  return [screenings, showDates, isLoading];
};

export default useScreeningsForCinemaOnDate;
