import { Cinema } from '@app/screening-times/src/api';

import CinemaAndScreeningsItem from '@app/screening-times/src/components/results-sections/CinemaAndScreeningsItem';
import {
  CinemaResultsContainer,
  CinemasColumn,
} from '@app/screening-times/src/components/results-sections/StyledResultsComponents';

const splitCinemas = cinemas => {
  const middle = Math.ceil(cinemas.length / 2);
  return [cinemas.slice(0, middle), cinemas.slice(middle)];
};

type ListOfCinemasProps = {
  filmId: number;
  cinemas: Cinema[];
  setSelectedCinema?: (cinema: Cinema) => void;
  showClosestDate?: boolean;
  countryCode: string;
};

const ListOfCinemas = ({
  filmId,
  cinemas,
  setSelectedCinema = null,
  showClosestDate = false,
  countryCode,
}: ListOfCinemasProps) => {
  const [cinemasInLeftColumn, cinemasInRightColumn] = splitCinemas(cinemas);
  return (
    <CinemaResultsContainer>
      <CinemasColumn>
        {cinemasInLeftColumn.map(cinema => (
          <CinemaAndScreeningsItem
            key={cinema.cinema_id}
            cinema={cinema}
            setSelectedCinema={setSelectedCinema}
            filmId={filmId}
            showClosestDate={showClosestDate}
            countryCode={countryCode}
          />
        ))}
      </CinemasColumn>
      <CinemasColumn>
        {cinemasInRightColumn.map(cinema => (
          <CinemaAndScreeningsItem
            key={cinema.cinema_id}
            cinema={cinema}
            setSelectedCinema={setSelectedCinema}
            filmId={filmId}
            showClosestDate={showClosestDate}
            countryCode={countryCode}
          />
        ))}
      </CinemasColumn>
    </CinemaResultsContainer>
  );
};

export default ListOfCinemas;
