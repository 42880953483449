import { Cinema } from '@app/screening-times/src/api';

import useClosestCinemasOnDate from '@app/screening-times/src/hooks/useClosestCinemasOnDate';

import ListOfCinemas from '@app/screening-times/src/components/results-sections/ListOfCinemas';
import LoadingState from '@app/screening-times/src/components/utils/LoadingState';
import NoResultsCopy from '@app/screening-times/src/components/utils/NoResultsCopy';

type ClosestCinemasOnDateProps = {
  filmId: number;
  latLongString: string;
  dateString: string;
  setSelectedCinema: (cinema: Cinema) => void;
  countryCode: string;
};

const ClosestCinemasOnDate = ({
  filmId,
  latLongString,
  dateString,
  setSelectedCinema,
  countryCode,
}: ClosestCinemasOnDateProps) => {
  const [cinemas, isLoading] = useClosestCinemasOnDate(
    filmId,
    latLongString,
    dateString,
    countryCode,
  );

  if (isLoading) {
    return <LoadingState />;
  }

  if (!Array.isArray(cinemas) || cinemas.length === 0) {
    return (
      <NoResultsCopy>
        There are currently no screenings available near this location on
        selected date
      </NoResultsCopy>
    );
  }

  return (
    <ListOfCinemas
      filmId={filmId}
      cinemas={cinemas}
      setSelectedCinema={setSelectedCinema}
      countryCode={countryCode}
    />
  );
};

export default ClosestCinemasOnDate;
