import styled from '@emotion/styled';

import { LoadingIcon } from '@app/screening-times/src/components/Icons';

const LoadingContainer = styled.div`
  margin: 200px auto;
  width: 80px;
`;

const LoadingState = () => (
  <LoadingContainer data-cy="loading-state">
    <LoadingIcon />
  </LoadingContainer>
);

export default LoadingState;
