import { useEffect } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

import { round } from '@app/screening-times/src/services';

const useAutocomplete = (initialLat, initialLong) => {
  const {
    init,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
  });

  const doFillInInitialLocation = async () => {
    try {
      let geocodeResults = await getGeocode({
        location: { lat: initialLat, lng: initialLong },
      });

      // filter out the user unfriendly looking plus_code result type
      geocodeResults = geocodeResults.filter(
        result => !result.types.includes('plus_code'),
      );
      const localityResult = geocodeResults.find(result =>
        result.types.includes('locality'),
      );
      const postalTownResult = geocodeResults.find(result =>
        result.types.includes('postal_town'),
      );
      const postalCodeResult = geocodeResults.find(result =>
        result.types.includes('postal_code'),
      );
      const resultToDisplay =
        localityResult ||
        postalTownResult ||
        postalCodeResult ||
        geocodeResults[0];

      setValue(resultToDisplay?.formatted_address, false);
    } catch {
      setValue('');
    }
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyBYQns60NAMU-P44MLV9uahc9HhNRX696Y',
      version: 'weekly',
      libraries: ['places'],
    });
    loader.loadCallback(() => {
      init();
      doFillInInitialLocation();
    });
  }, []);

  return { value, suggestions: data, setValue, clearSuggestions };
};

export const getLatLongStringAndCountryFromDescription = async description => {
  try {
    const geocodeResults = await getGeocode({ address: description });

    const { lat, lng } = await getLatLng(geocodeResults[0]);
    const country = geocodeResults[0].address_components.find(address =>
      address?.types?.includes('country'),
    );

    return [`${round(lat)};${round(lng)}`, country?.short_name];
  } catch {
    // do nothing
    return ['', ''];
  }
};

export default useAutocomplete;
